export default {
    token: {
        // Seed Token
        itemMarginBottom: 0,
        colorText: "#213973"
        // Alias Token
    },
    components: {
        Radio: {
            buttonColor: "#213973",
            radioSize: 20
        },
      },
};