import { Radio } from "antd";
import FormInputDivider from "./FormInputDivider";
import { StyledForm } from "../styles";
import { useEffect, useState } from "react";

function PortalRadio({ input, divider, style, ...props }) {
  const [customStyle, setCustomStyle] = useState({});

  useEffect(
    () => {
      if (style?.align == "center") {
        if (style?.optionType == "button") {
          setCustomStyle({textAlign: "center"})
        }
        else {
          setCustomStyle({display: "flex", justifyContent: "center"})
        }
      }
    },[]
  )
  return (
    <FormInputDivider key={input.name} {...divider}>
      <StyledForm.Item {...input} extra={props.helpMessage}>
          <Radio.Group
            disabled={input?.disabled}
            options={input.options}
            optionType={style?.optionType}
            buttonStyle={style?.buttonStyle}
            style={customStyle}
          />
      </StyledForm.Item>
    </FormInputDivider>
  );
}

export default PortalRadio;