import React from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { createRouters } from './routerHelpers'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import ReactAI from 'react-appinsights';

/* Pages */
import { Home } from '../pages/Home';
import { Login } from '../pages/Login';
import { Provisionamento } from '../pages/Provisionamento';
import { EsqueciMinhaSenha } from '../pages/EsqueciMinhaSenha';
import Error401 from '../pages/errorPages/401';
import Error403 from '../pages/errorPages/403';
import Error404 from '../pages/errorPages/404';
import Error503 from '../pages/errorPages/503';
import EnviarDadosNavegacao from "EstruturaAntiga/actions/Navegacao/EnviarDadosNavegacao";
import PesquisaSatisfacao from 'pages/Atendimentos/PesquisaSatisfacao'
import PesquisaSatisfacaoConclusao from 'pages/Atendimentos/PesquisaSatisfacao/Components/PesquisaSatisfacaoConclusao'

ReactAI.init({ instrumentationKey: '067b3f02-0222-4a8c-852a-e4f571da0518' });

class Rota extends React.Component {
	render() {
		var history = createBrowserHistory();		
		const contact = JSON.parse(sessionStorage.getItem('contact'));
		if (contact) {
			history.listen(function (location) {
				EnviarDadosNavegacao (location.pathname);
	
				const gtag = window.gtag;
				gtag('config', 'UA-44364102-8', {
					'page_title': document.title,
					'page_location': window.location.href,
					'page_path': location.pathname,
					'contact_id': contact && contact.idContact
				});
			});
		}

		return (
			<Router key={Math.random()} history={history}>
				<Switch>
					{createRouters()}
					<Redirect from="/null" to="/" />
					<Route path="/" exact component={Home} />
					<Route path="/login" component={Login} />
					<Route path="/provisionamento" component={Provisionamento} />
					<Route path="/esqueci-minha-senha" component={EsqueciMinhaSenha} />
					<Route path="/pesquisa-satisfacao" component={PesquisaSatisfacao} />
					<Route path="/pesquisa-satisfacao-conclusao" component={PesquisaSatisfacaoConclusao} />
					<Route path="/401" component={Error401} />
					<Route path="/403" component={Error403} />
					<Route path="/404" component={Error404} />
					<Route path="/503" component={Error503} />
					<Route component={Error404} />
				</Switch>
			</Router>
		)
	}
}

export default withRouter(connect(state => ({
	listaTermos: state.termos.listaTermos,
	selectedTerm: state.termos.selectedTerm,
	error: state.router.error
}))(Rota));
