import { useEffect, useState } from "react"
import { withRouter  } from 'react-router-dom'
import { NotificationManager } from 'react-notifications';
import Formulario from "components/Formulario/Formulario";
import { StyledForm } from "components/Formulario/styles";
import { MessagesDefaultTimeout } from "constants/messagesDefaultTimeout";
import PesquisasService from "services/PesquisasService";
import Logo from 'assets/images/logo.png';
import './style.scss';
import queryString from 'query-string'
import LoadingOrErrorPanel from "components/LoadingOrErrorPanel";

function PesquisaSatisfacao({ history, ...props}){
    const [loading, setLoading] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');
    const [inputsFormulario, setInputsFormulario] = useState([]);
    const [form] = StyledForm.useForm();
    const [perguntas, setPerguntas] = useState({});
    const [ocorrencia, setOcorrencia] = useState({});
    const [dataInicio, setDataInicio] = useState(new Date());
    const [identificadorPesquisa, setIdentificadorPesquisa] = useState('');
    const [parametros, setParametros] = useState({});

    useEffect(()=>{
        const {ocorrenciaId, nota} = queryString.parse(props.location.search);
        if (!ocorrenciaId){
            setMensagemErro('Id da ocorrência não informado.');
            setHasError(true);
            setLoading(false);
            return;
        }

        CriarFormulario(ocorrenciaId);
        setParametros({ocorrenciaId, nota});
    },[]);

    const CriarFormulario = async (ocorrenciaId) => {
        await PesquisasService.ObterPesquisaSatisfacaoOcorrencia(ocorrenciaId)
            .then(response => {
                setIdentificadorPesquisa(response.identificador);
                setOcorrencia(response.ocorrencia);
                
                var inputs = GerarInputsPadrao(response.perguntas)

                setInputsFormulario(inputs);
                setDataInicio(new Date());
                setLoading(false);
            })
            .catch(error => {
                setMensagemErro(error.response?.data?.erros[0] ?? 'Erro ao obter a pesquisa de satisfação');
                setHasError(true)
                setLoading(false)
            })
    }
    
    const GerarInputsPadrao = (perguntasPesquisa) => {
        let nps = perguntasPesquisa.find(x => x.tipoVisual == "nps");
        let opcoes = perguntasPesquisa.find(x => x.tipoVisual == "opcao");
        let comentario = perguntasPesquisa.find(x => x.tipoVisual == "texto");
        let autorizacaoPesquisa = perguntasPesquisa.find(x => x.tipoVisual == "checkbox");
        let codigoOcorrencia = perguntasPesquisa.find(x => x.tipoVisual == "oculto");
        setPerguntas({nps, opcoes, comentario, autorizacaoPesquisa, codigoOcorrencia});

        let retorno = [];
        retorno.push(...[
            { 
                input:{ 
                    name: nps.identificador,
                    label: nps.pergunta,
                    type: "radio",
                    options: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
                    rules: [{required: true, message:"Campo obrigatorio"}],
                    size: "large"
                },
                style: {align:"center"}
            },
            { 
                input:{ 
                    name: opcoes.identificador,
                    type: "radio",
                    options: opcoes.opcoes
                },
                style: {align:"center", optionType:"button", buttonStyle:"solid"}
            },
            { input:{ name: comentario.identificador, label: comentario.pergunta, type: "textarea"}},
            { input:{ name: autorizacaoPesquisa.identificador, type: "checkbox", options: [{label: autorizacaoPesquisa.pergunta, value: "Sim"}], hidden: true}}
        ]);
        
        return retorno;
    }
    
    const onFinish = async (values) => {
        setDisabled(true)
        setLoading(true)

        values[perguntas.codigoOcorrencia.identificador] = ocorrencia.numeroOcorrencia;
        if (values[perguntas.autorizacaoPesquisa.identificador] == undefined){
            values[perguntas.autorizacaoPesquisa.identificador] = "Não";
        }
        let respostas = Object.keys(values)
            .filter(chave => values[chave] !== undefined)
            .map(chave => ({
            identificador: chave,
            resposta: Array.isArray(values[chave]) ? values[chave][0] : values[chave]
          }));

        let dados = {
            identificador: identificadorPesquisa,
            codigoOcorrencia: ocorrencia.numeroOcorrencia,
            contatoId: ocorrencia.solicitanteId,
            contato: ocorrencia.nomeCompletoSolicitante,
            contatoEmail: ocorrencia.emailSolicitante,
            dataInicio,
            dataEnvio: new Date(),
            respostas
        }

        await PesquisasService.CriarRepostaPesquisaSatisfacao(dados)
            .then(() => {              
                history.push(`/pesquisa-satisfacao-conclusao`)
            })
            .catch(error => {
                NotificationManager.error(error.response?.data?.erros[0] ?? 'Ocorreu um erro ao registrar seu feedback.', 'Ocorreu um erro!', MessagesDefaultTimeout.Error);
                setDisabled(false)
                setLoading(false)
            })
    };

    const onValuesChange = (changedValues) => {
        if (perguntas.nps.identificador in changedValues || perguntas.comentario.identificador in changedValues) {
            if (form.getFieldValue(perguntas.nps.identificador) >= 9 && form.getFieldValue(perguntas.comentario.identificador)) {
                setHiddenAutorizacaoPesquisa(false);
            }
            else {
                setHiddenAutorizacaoPesquisa(true);
            }
        }
    };

    const setHiddenAutorizacaoPesquisa = (hiddenAutorizacaoPesquisa) => {
        let newInputs = [...inputsFormulario];
        let autorizacaoPesquisaIndex = newInputs.findIndex(x => x.input.name == perguntas.autorizacaoPesquisa.identificador);
        let autorizacaoPesquisaInput = newInputs[autorizacaoPesquisaIndex];
        newInputs[autorizacaoPesquisaIndex] = {
            ...autorizacaoPesquisaInput,
            input:{
                ...autorizacaoPesquisaInput.input,
                hidden: hiddenAutorizacaoPesquisa
            }
        }
        setInputsFormulario(newInputs);
    };

    return(
        <div className="pesquisa-satisfacao">
            <LoadingOrErrorPanel hasError={hasError} loading={loading} mensagemErro={mensagemErro}>
                <div className="formulario">
                    <img src={Logo} alt="BHS" />
                    <h2>Olá, {ocorrencia.nomeSolicitante ?? "cliente"}!</h2>
                    <h2>A sua solicitação foi concluída.</h2>
                    <h3>Obrigado por utilizar nosso suporte! Para melhorarmos continuamente, gostaríamos de saber como foi sua experiência. Seu feedback é essencial e a pesquisa levará apenas alguns minutos.</h3>
                
                <Formulario 
                    onFinish={onFinish}
                    inputsData={inputsFormulario} 
                    disabled={disabled}
                    formReference={form}
                    initialValues={{[perguntas?.nps?.identificador]: parametros.nota}}
                    onValuesChange={onValuesChange}
                    submitButtonAlign="center"
                />
                </div>
            </LoadingOrErrorPanel>
        </div>
    )
}

export default withRouter(PesquisaSatisfacao)