import { withRouter  } from 'react-router-dom'
import Logo from 'assets/images/logo.png';
import '../style.scss';

function PesquisaSatisfacaoConclusao({ history, ...props}){
    return(
        <div className="pesquisa-satisfacao">
            <div className="formulario">
                <img src={Logo} alt="BHS" />
                <h2>Agradecemos por preencher nossa pesquisa de satisfação.</h2>
                <h3>Sua opinião é fundamental para que possamos oferecer um serviço cada vez melhor.</h3>
                <h3>Em casos de sugestões ou reclamações, sinta-se à vontade para nos acionar: <a href="mailto:sucessodocliente@bhs.com.br">sucessodocliente@bhs.com.br</a></h3>
            </div>
        </div>
    )
}

export default withRouter(PesquisaSatisfacaoConclusao)