import PortalClienteService from "services/bases/PortalClienteService";

class PesquisasService extends PortalClienteService{
    async ObterPesquisaSatisfacaoOcorrencia(ocorrenciaId){
        return this.get(`pesquisas/ObterPesquisaSatisfacaoOcorrencia?ocorrenciaId=${ocorrenciaId}`, false)
    }
    async CriarRepostaPesquisaSatisfacao(respostaPesquisaDTO){
        return this.post(`pesquisas/CriarRespostaPesquisaSatisfacao`, respostaPesquisaDTO, false)
    }
}

export default new PesquisasService();